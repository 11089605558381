import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import downloads from '../assets/images/downloads.jpg';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const DownloadsPageStyled = styled.div`
  width: 100%;
  .dl-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${downloads});
  }
  .downloads-list {
    display: grid;
    padding: 2rem 0;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  h3 {
    font-size: 1.9rem;
  }
  .downloads-list ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    li {
      margin: 1rem 0 2rem;
    }
    li a {
      padding: 1rem;
      background: var(--backgroundCard);
      text-decoration: none;
      box-shadow: var(--boxShadow);
      border-radius: 0.5rem;
      transition: var(--speed);
      &:hover {
        color: var(--white);
        background: var(--bont);
      }
    }
  }
`;

export default function DownloadsPage({
  data: { support, technology, products },
}) {
  const supportDownloads = support.nodes[0].supportPages.filter(
    (item) => item.downloads.length > 0
  );
  const technologyDownloads = technology.nodes[0].technologyPages.filter(
    (item) => item.downloads.length > 0
  );
  const productDownloads = products.nodes.filter(
    (item) => item.downloads.length > 0
  );
  return (
    <>
      <SEO title="Join our Bont Pilot program" />
      <DownloadsPageStyled>
        <div className="wrapper">
          <section className="page-header dl-header">
            <div>
              <h2>Downloads</h2>
            </div>
          </section>
          <section className="downloads-list">
            <div>
              <h3>Support Downloads</h3>
              <ul>
                {supportDownloads.map((source) =>
                  source.downloads.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.download.asset.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.fileName} - {source.title}
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div>
              <h3>Technology Downloads</h3>
              <ul>
                {technologyDownloads.map((source) =>
                  source.downloads.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item?.download?.asset.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.fileName} - {source.title}
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div>
              <h3>Product-specific Downloads</h3>
              <ul>
                {productDownloads.map((source) =>
                  source.downloads.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item?.download?.asset.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.fileName} - {source.title}
                      </a>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </section>
        </div>
        <NavPages />
      </DownloadsPageStyled>
    </>
  );
}

export const query = graphql`
  query {
    products: allSanityProducts {
      nodes {
        downloads {
          fileName
          download {
            asset {
              url
              mimeType
              title
            }
          }
        }
        title
        slug {
          current
        }
      }
    }
    support: allSanitySupport {
      nodes {
        supportPages {
          downloads {
            fileName
            download {
              asset {
                url
                mimeType
              }
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
    technology: allSanityTechnology {
      nodes {
        technologyPages {
          title
          slug {
            current
          }
          downloads {
            fileName
            download {
              asset {
                mimeType
                url
              }
            }
          }
        }
      }
    }
  }
`;
